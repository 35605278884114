/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */

import "styles/pages/page-gallery.scss";
import React, { useState } from "react";
import Slider from "react-slick";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import Breadcrumbs from "components/Breadcrumbs";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const GalleryPage = ({ pageContext }) => {
	const galleryShort = pageContext.data.pageGallery.mainGallery;

	const gallerySlider = {
		dots: true,
		infinite: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		draggable: false,
		autoplay: true,
		autoplaySpeed: 5000,
		speed: 1000,
		pauseOnHover: false,
	};

	const [photoIndex, setPhotoIndex] = useState(0);
	const [isLightBoxOpen, setIsLightBoxOpen] = useState(false);
	const [sliderIndex, setSliderIndex] = useState(0);

	const galleryComplete = galleryShort.map((it) => it.gallery); // [[1,2,3,4,5,6], [7,8,9,10,11,12]]
	const flatList = galleryShort.flatMap((it) => it.gallery); // [1,2,3,4,5,6,7,8,9,10,11,12]

	const getIndexAtOffset = (offset) => {
		return offset > 0
			? (photoIndex + offset) % flatList.length
			: (photoIndex + flatList.length + offset) % flatList.length;
	};

	const getImageAtOffset = (offset) => {
		return flatList[getIndexAtOffset(offset)];
	};

	return (
		<Layout headerColor="#9300B9">
			<Seo
				title={
					pageContext.seo.title
						? pageContext.seo.title
						: pageContext.title
				}
				description={
					pageContext.seo.metaDesc && pageContext.seo.metaDesc
				}
			/>
			<SubpageHeader
				image={require("assets/images/galeria-bg-main.jpg").default}
			/>
			<Breadcrumbs currentLocationLabel="Galeria" />
			<section className="gallery">
				<div className="container">
					<Slider {...gallerySlider}>
						{galleryComplete.map((nodes, index) => {
							const slice = flatList.slice(
								6 * index,
								6 * index + 6
							);
							const dict = slice.map((n, i) => ({
								url: n.sourceUrl,
								index: 6 * index + i,
							}));
							return (
								<>
									<div className="row" key={index}>
										<div className="col-xl-2">
											<div className="gallery__square gallery__square--normal-1"></div>
											<div
												className="img-fluid gallery__image gallery__image--1"
												onClick={(e) => {
													e.preventDefault();
													setIsLightBoxOpen(true);
													setPhotoIndex(
														dict[0].index
													);
												}}
												aria-hidden="true"
												style={{
													backgroundImage: `url(${dict[0]?.url})`,
												}}
											/>
											<div className="gallery__square gallery__square--normal-2"></div>
										</div>
										<div className="col-xl-6">
											<div
												className="img-fluid gallery__image gallery__image--2"
												onClick={(e) => {
													e.preventDefault();
													setIsLightBoxOpen(true);
													setPhotoIndex(
														dict[1].index
													);
												}}
												style={{
													backgroundImage: `url(${dict[1]?.url})`,
												}}
											/>
										</div>
										<div className="col-xl-4">
											<div className="gallery__container">
												<div className="gallery__square gallery__square--long"></div>
												<div
													className="img-fluid gallery__image gallery__image--3"
													onClick={(e) => {
														e.preventDefault();
														setIsLightBoxOpen(true);
														setPhotoIndex(
															dict[2].index
														);
													}}
													style={{
														backgroundImage: `url(${dict[2]?.url})`,
													}}
												/>
											</div>
											<div className="gallery__container gallery__container--reverse">
												<div className="gallery__square gallery__square--long"></div>
												<div
													className="img-fluid gallery__image gallery__image--4"
													onClick={(e) => {
														e.preventDefault();
														setIsLightBoxOpen(true);
														setSliderIndex(index);
														setPhotoIndex(
															dict[3].index
														);
													}}
													style={{
														backgroundImage: `url(${dict[3]?.url})`,
													}}
												/>
											</div>
										</div>
									</div>
									<div className=" row gallery__row">
										<div className="col-xl-4">
											<div
												className="img-fluid gallery__image gallery__image--5"
												onClick={(e) => {
													e.preventDefault();
													setIsLightBoxOpen(true);
													setPhotoIndex(
														dict[4].index
													);
												}}
												style={{
													backgroundImage: `url(${dict[4]?.url})`,
												}}
											/>
										</div>
										<div className="col-xl-3">
											<div className="gallery__square gallery__square--large"></div>
										</div>
										<div className="col-xl-5 gallery__special-column">
											<div
												className="img-fluid gallery__image gallery__image--6"
												onClick={(e) => {
													e.preventDefault();
													setIsLightBoxOpen(true);
													setPhotoIndex(
														dict[5].index
													);
												}}
												style={{
													backgroundImage: `url(${dict[5]?.url})`,
												}}
											/>
										</div>
									</div>
								</>
							);
						})}
					</Slider>
				</div>
			</section>
			{isLightBoxOpen && (
				<Lightbox
					mainSrc={getImageAtOffset(0).sourceUrl}
					nextSrc={getImageAtOffset(1).sourceUrl}
					prevSrc={getImageAtOffset(-1).sourceUrl}
					onCloseRequest={() => setIsLightBoxOpen(false)}
					onMovePrevRequest={() =>
						setPhotoIndex(getIndexAtOffset(-1))
					}
					onMoveNextRequest={() => setPhotoIndex(getIndexAtOffset(1))}
				/>
			)}
		</Layout>
	);
};

export default GalleryPage;
